import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { RouteProps } from 'react-router'
import { selectAuthenticationStatus } from '../store/slices/applicationSlice'
import { useSelector } from 'react-redux'

export const ProtectedRoute: React.FC<RouteProps> = (props) => {
  const isAuthenticated = useSelector(selectAuthenticationStatus)

  if (isAuthenticated) {
    return <Route {...props} />
  }

  return <Redirect to={'/sign-in'} />
}
