import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

interface IUser {
  email: string
}

interface IInitialState {
  user: {
    email: string | null
    isAuthenticated: boolean
  }
}

const initialState: IInitialState = {
  user: {
    email: null,
    isAuthenticated: false,
  },
}

type AddUserPayloadAction = PayloadAction<IUser>

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setUser: (state, action: AddUserPayloadAction) => {
      const { email } = action.payload
      state.user.email = email
    },
    removeUser: (state) => {
      state.user.email = null
    },
    setAuthenticationStatus: (state, action: PayloadAction<boolean>) => {
      state.user.isAuthenticated = action.payload
    },
  },
})

export const { setUser, removeUser, setAuthenticationStatus } = applicationSlice.actions

export const selectCurrentUser = (state: RootState) => state.application.user
export const selectAuthenticationStatus = (state: RootState) => state.application.user.isAuthenticated
