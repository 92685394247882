import React, { useEffect, useState } from 'react'
import styles from '../RecordsDrawer.module.scss'
import { Badge, Form, FormProps, InputNumber, message, Tabs } from 'antd'
import { recordsDrawerFormFields } from '../recordsDrawerFormFields'
import { recordsDrawerFormFieldsDist } from '../recordsDrawerFormFieldsDist'
import { recordsDrawerFormFieldsProx } from '../recordsDrawerFormFieldsProx'
import { recordsDrawerFormFieldsDistDt } from '../recordsDrawerFormFieldsDistDt'
import { recordsDrawerFormFieldsProxDt } from '../recordsDrawerFormFieldsProxDt'
import { IRecord } from '../../../../models/Records.model'
import { useForm } from 'antd/es/form/Form'
import { IRecordsDrawerProps } from '../RecordsDrawer'
import { useDispatch, useSelector } from 'react-redux'
import { addRecordsData, updateRecordsData } from '../../../../store/slices/recordsSlice'
import { selectRecordsDrawerFormSelectedRecord } from '../../../../store/slices/recordsDrawerSlice'

const { TabPane } = Tabs

interface RecordsDrawerContentProps {
  isFormSubmittingSetter?: React.Dispatch<React.SetStateAction<boolean>>
  handlerOnClose?: IRecordsDrawerProps['handlerOnClose']
}

type IRecordKeys = keyof IRecord

export const RecordsDrawerContent: React.FC<RecordsDrawerContentProps> = ({
  isFormSubmittingSetter,
  handlerOnClose,
}) => {
  const dispatch = useDispatch()
  const [formInstance] = useForm<IRecord>()
  const [isBadgeActivated, setIsBadgeActivated] = useState(false)

  const selectedRecord = useSelector(selectRecordsDrawerFormSelectedRecord)

  useEffect(() => {
    if (selectedRecord) {
      formInstance.setFieldsValue(selectedRecord)
    }
  }, [selectedRecord])

  const handleFormFinish: FormProps<IRecord>['onFinish'] = async (values) => {
    setIsBadgeActivated(false)

    if (selectedRecord) {
      const foo = (base: IRecord, edited: IRecord) => {
        const keys = Object.keys(edited)
        const changedValues: Partial<IRecord> = {}
        keys.map((key) => {
          if (base[key as IRecordKeys] !== edited[key as IRecordKeys]) {
            changedValues[key as IRecordKeys] = edited[key as IRecordKeys]
          }
        })

        return changedValues
      }

      const removeFalsy = (obj: Partial<IRecord>) => {
        let newObj: Partial<IRecord> = {}
        Object.keys(obj).forEach((prop) => {
          if (obj[prop as IRecordKeys] === null) {
            newObj[prop as IRecordKeys] = undefined
          } else {
            newObj[prop as IRecordKeys] = obj[prop as IRecordKeys]
          }
        })
        return newObj
      }

      const isSuccess = await dispatch(
        updateRecordsData(selectedRecord['_id'], removeFalsy(foo(selectedRecord, values)))
      )

      if (!isSuccess) {
        message.error('Messergebnis konnte nicht aktualisiert werden')
      } else {
        handlerOnClose && handlerOnClose()
        isFormSubmittingSetter && isFormSubmittingSetter(false)
      }
    } else {
      const success = await dispatch(addRecordsData([values]))

      if (!success) {
        message.error('Messergebnis konnte nicht gespeichert werden')
      } else {
        handlerOnClose && handlerOnClose()
        isFormSubmittingSetter && isFormSubmittingSetter(false)
      }
    }
  }

  const handleFinishFailed: FormProps<IRecord>['onFinishFailed'] = () => {
    if (!isBadgeActivated) {
      setIsBadgeActivated(true)
    }
  }

  const handleValuesChange: FormProps<IRecord>['onValuesChange'] = (changedValues, allValues) => {
    const { bmi } = changedValues

    if (!bmi) {
      const { bodyHeight, weight } = allValues

      if (bodyHeight && weight) {
        const bmi = Number((weight / Math.pow(bodyHeight / 100, 2)).toFixed(2))
        formInstance.setFieldsValue({ bmi })
      }
    }
  }

  return (
    <Form
      form={formInstance}
      layout={'vertical'}
      name={'records-form'}
      onFinish={handleFormFinish}
      onFinishFailed={handleFinishFailed}
      onValuesChange={handleValuesChange}
      className={styles.form}
    >
      <Tabs className={styles.tabs}>
        <TabPane
          tab={<Badge dot={isBadgeActivated}>Basic</Badge>}
          key={'standard'}
          className={styles.tabsPane}
          forceRender
        >
          {recordsDrawerFormFields.map((record, index) => (
            <Form.Item key={`${record}-${index}`} {...record}>
              {record.children ? record.children : <InputNumber className={styles.numberInput} />}
            </Form.Item>
          ))}
        </TabPane>
        <TabPane tab={'Dist'} key={'dist'} className={styles.tabsPane} forceRender>
          {recordsDrawerFormFieldsDist.map((record, index) => (
            <Form.Item key={`${record.name}-${index}`} {...record}>
              <InputNumber className={styles.numberInput} />
            </Form.Item>
          ))}
        </TabPane>
        <TabPane tab={'Prox'} key={'prox'} className={styles.tabsPane} forceRender>
          {recordsDrawerFormFieldsProx.map((record, index) => (
            <Form.Item key={`${record.name}-${index}`} {...record}>
              <InputNumber className={styles.numberInput} />
            </Form.Item>
          ))}
        </TabPane>
        <TabPane tab={'Dist dT'} key={'distDt'} className={styles.tabsPane} forceRender>
          {recordsDrawerFormFieldsDistDt.map((record, index) => (
            <Form.Item key={`${record.name}-${index}`} {...record}>
              <InputNumber className={styles.numberInput} />
            </Form.Item>
          ))}
        </TabPane>
        <TabPane tab={'Prox dT'} key={'proxDt'} className={styles.tabsPane} forceRender>
          {recordsDrawerFormFieldsProxDt.map((record, index) => (
            <Form.Item key={`${record.name}-${index}`} {...record}>
              <InputNumber className={styles.numberInput} />
            </Form.Item>
          ))}
        </TabPane>
      </Tabs>
    </Form>
  )
}
