import { ColumnsType } from 'antd/es/table'
import { IRecord } from '../../../models/Records.model'

export const recordsTableColumnsDistDt: ColumnsType<IRecord> = [
  {
    title: 'StO2[%]dist_03_01_dT',
    dataIndex: 'StO2_dist_03_01_dT',
  },
  {
    title: 'StO2[%]dist_06_01_dT',
    dataIndex: 'StO2_dist_06_01_dT',
  },
  {
    title: 'StO2[%]dist_09_01_dT',
    dataIndex: 'StO2_dist_09_01_dT',
  },
  {
    title: 'StO2[%]dist_12_01_dT',
    dataIndex: 'StO2_dist_12_01_dT',
  },
  {
    title: 'StO2[%]dist_tot_01_dT',
    dataIndex: 'StO2_dist_tot_01_dT',
  },
  {
    title: 'NPI[%]dist_03_01_dT',
    dataIndex: 'NPI_dist_03_01_dT',
  },
  {
    title: 'NPI[%]dist_06_01_dT',
    dataIndex: 'NPI_dist_06_01_dT',
  },
  {
    title: 'NPI[%]dist_09_01_dT',
    dataIndex: 'NPI_dist_09_01_dT',
  },
  {
    title: 'NPI[%]dist_12_01_dT',
    dataIndex: 'NPI_dist_12_01_dT',
  },
  {
    title: 'NPI[%]dist_tot_01_dT',
    dataIndex: 'NPI_dist_tot_01_dT',
  },
  {
    title: 'THI[%]dist_03_01_dT',
    dataIndex: 'THI_dist_03_01_dT',
  },
  {
    title: 'THI[%]dist_06_01_dT',
    dataIndex: 'THI_dist_06_01_dT',
  },
  {
    title: 'THI[%]dist_09_01_dT',
    dataIndex: 'THI_dist_09_01_dT',
  },
  {
    title: 'THI[%]dist_12_01_dT',
    dataIndex: 'THI_dist_12_01_dT',
  },
  {
    title: 'THI[%]_dist_tot_01_dT',
    dataIndex: 'THI_dist_tot_01_dT',
  },
  {
    title: 'TWI[%]dist_03_01_dT',
    dataIndex: 'TWI_dist_03_01_dT',
  },
  {
    title: 'TWI[%]dist_06_01_dT',
    dataIndex: 'TWI_dist_06_01_dT',
  },
  {
    title: 'TWI[%]dist_09_01_dT',
    dataIndex: 'TWI_dist_09_01_dT',
  },
  {
    title: 'TWI[%]dist_12_01_dT',
    dataIndex: 'TWI_dist_12_01_dT',
  },
  {
    title: 'TWI[%]_dist_tot_01_dT',
    dataIndex: 'TWI_dist_tot_01_dT',
  },
]
