import React from 'react'
import { FormItemProps, Input, Radio } from 'antd'
import { ESex, ESkinType, IRecord } from '../../../models/Records.model'

export const recordsDrawerFormFields: FormItemProps<IRecord>[] = [
  {
    label: 'Probanden ID',
    name: 'subjectId',
    rules: [{ required: true, message: 'Probanden ID fehlt.' }],
    children: <Input />,
  },
  {
    label: 'Diagnosegruppe',
    name: 'diagnosisGroup',
    rules: [{ required: true, message: 'Diagnosegruppe fehlt.' }],
    children: <Input />,
  },
  {
    label: 'Hauttyp',
    name: 'skinType',
    rules: [{ required: true, message: 'Hauttyp fehlt.' }],
    children: (
      <Radio.Group>
        <Radio.Button value={ESkinType.DARK}>dunkel</Radio.Button>
        <Radio.Button value={ESkinType.LIGHT}>hell</Radio.Button>
      </Radio.Group>
    ),
  },
  {
    label: 'Alter',
    name: 'age',
    rules: [{ required: true, message: 'Alter fehlt.' }],
  },
  {
    label: 'Geschlecht',
    name: 'sex',
    rules: [{ required: true, message: 'Geschlecht fehlt.' }],
    children: (
      <Radio.Group>
        <Radio.Button value={ESex.FEMALE}>weiblich</Radio.Button>
        <Radio.Button value={ESex.MALE}>männlich</Radio.Button>
      </Radio.Group>
    ),
  },
  {
    label: 'Körperhöhe [cm]',
    name: 'bodyHeight',
    rules: [{ required: true, message: 'Körperhöhe fehlt.' }],
  },
  {
    label: 'Gewicht [kg]',
    name: 'weight',
    rules: [{ required: true, message: 'Gewicht fehlt.' }],
  },
  {
    label: 'BMI [kg/m²]',
    name: 'bmi',
  },
  {
    label: 'Raucherstatus',
    name: 'smokerStatus',
    children: (
      <Radio.Group>
        <Radio.Button value={true}>Ja</Radio.Button>
        <Radio.Button value={false}>Nein</Radio.Button>
      </Radio.Group>
    ),
  },
  {
    label: 'Pre proBNP [ng/ml]',
    name: 'preProBnp',
  },
  {
    label: 'Post proBNP [ng/ml]',
    name: 'postProBnp',
  },
  {
    label: 'T_derm [°C]',
    name: 'T_derm',
  },
  {
    label: 'T_ambient [°C]_02',
    name: 'T_ambient_02',
  },
  {
    label: 'tcpO2_dist[mmHg]_01',
    name: 'tcpO2_dist_01',
  },
  {
    label: 'tcpO2_prox[mmHg]_01',
    name: 'tcpO2_prox_01',
  },
  {
    label: 'tcpO2_dist[mmHg]_02',
    name: 'tcpO2_dist_02',
  },
  {
    label: 'tcpO2_prox[mmHg]_02',
    name: 'tcpO2_prox_02',
  },
]
