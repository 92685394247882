import { Document } from 'mongoose'

export enum ESex {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum ESkinType {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}

export interface IRecord extends Document {
  subjectId: string
  diagnosisGroup: string
  skinType: ESkinType
  age: number
  sex: ESex
  bodyHeight: number
  bmi?: number
  weight: number
  smokerStatus?: boolean
  preProBnp?: number
  postProBnp?: number
  T_ambient_02?: number
  T_derm?: number
  tcpO2_prox_01?: number
  tcpO2_dist_01?: number
  tcpO2_prox_02?: number
  tcpO2_dist_02?: number
  // -> Neon Green
  StO2_dist_03_01?: number
  StO2_dist_06_01?: number
  StO2_dist_09_01?: number
  StO2_dist_12_01?: number
  StO2_dist_tot_01?: number
  NPI_dist_03_01?: number
  NPI_dist_06_01?: number
  NPI_dist_09_01?: number
  NPI_dist_12_01?: number
  NPI_dist_tot_01?: number
  THI_dist_03_01?: number
  THI_dist_06_01?: number
  THI_dist_09_01?: number
  THI_dist_12_01?: number
  THI_dist_tot_01?: number
  TWI_dist_03_01?: number
  TWI_dist_06_01?: number
  TWI_dist_09_01?: number
  TWI_dist_12_01?: number
  TWI_dist_tot_01?: number
  // -> Orange
  StO2_prox_03_01?: number
  StO2_prox_06_01?: number
  StO2_prox_09_01?: number
  StO2_prox_12_01?: number
  StO2_prox_tot_01?: number
  NPI_prox_03_01?: number
  NPI_prox_06_01?: number
  NPI_prox_09_01?: number
  NPI_prox_12_01?: number
  NPI_prox_tot_01?: number
  THI_prox_03_01?: number
  THI_prox_06_01?: number
  THI_prox_09_01?: number
  THI_prox_12_01?: number
  THI_prox_tot_01?: number
  TWI_prox_03_01?: number
  TWI_prox_06_01?: number
  TWI_prox_09_01?: number
  TWI_prox_12_01?: number
  TWI_prox_tot_01?: number
  // -> Turquoise
  StO2_dist_03_01_dT?: number
  StO2_dist_06_01_dT?: number
  StO2_dist_09_01_dT?: number
  StO2_dist_12_01_dT?: number
  StO2_dist_tot_01_dT?: number
  NPI_dist_03_01_dT?: number
  NPI_dist_06_01_dT?: number
  NPI_dist_09_01_dT?: number
  NPI_dist_12_01_dT?: number
  NPI_dist_tot_01_dT?: number
  THI_dist_03_01_dT?: number
  THI_dist_06_01_dT?: number
  THI_dist_09_01_dT?: number
  THI_dist_12_01_dT?: number
  THI_dist_tot_01_dT?: number
  TWI_dist_03_01_dT?: number
  TWI_dist_06_01_dT?: number
  TWI_dist_09_01_dT?: number
  TWI_dist_12_01_dT?: number
  TWI_dist_tot_01_dT?: number
  // -> dark blue
  StO2_prox_03_01_dT?: number
  StO2_prox_06_01_dT?: number
  StO2_prox_09_01_dT?: number
  StO2_prox_12_01_dT?: number
  StO2_prox_tot_01_dT?: number
  NPI_prox_03_01_dT?: number
  NPI_prox_06_01_dT?: number
  NPI_prox_09_01_dT?: number
  NPI_prox_12_01_dT?: number
  NPI_prox_tot_01_dT?: number
  THI_prox_03_01_dT?: number
  THI_prox_06_01_dT?: number
  THI_prox_09_01_dT?: number
  THI_prox_12_01_dT?: number
  THI_prox_tot_01_dT?: number
  TWI_prox_03_01_dT?: number
  TWI_prox_06_01_dT?: number
  TWI_prox_09_01_dT?: number
  TWI_prox_12_01_dT?: number
  TWI_prox_tot_01_dT?: number
}
