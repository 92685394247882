import { FormItemProps } from 'antd'
import { IRecord } from '../../../models/Records.model'

export const recordsDrawerFormFieldsProxDt: FormItemProps<IRecord>[] = [
  {
    label: 'StO2[%]prox_03_01_dT',
    name: 'StO2_prox_03_01_dT',
  },
  {
    label: 'StO2[%]prox_06_01_dT',
    name: 'StO2_prox_06_01_dT',
  },
  {
    label: 'StO2[%]prox_09_01_dT',
    name: 'StO2_prox_09_01_dT',
  },
  {
    label: 'StO2[%]prox_12_01_dT',
    name: 'StO2_prox_12_01_dT',
  },
  {
    label: 'StO2[%]prox_tot_01_dT',
    name: 'StO2_prox_tot_01_dT',
  },
  {
    label: 'NPI[%]prox_03_01_dT',
    name: 'NPI_prox_03_01_dT',
  },
  {
    label: 'NPI[%]prox_06_01_dT',
    name: 'NPI_prox_06_01_dT',
  },
  {
    label: 'NPI[%]prox_09_01_dT',
    name: 'NPI_prox_09_01_dT',
  },
  {
    label: 'NPI[%]prox_12_01_dT',
    name: 'NPI_prox_12_01_dT',
  },
  {
    label: 'NPI[%]prox_tot_01_dT',
    name: 'NPI_prox_tot_01_dT',
  },
  {
    label: 'THI[%]prox_03_01_dT',
    name: 'THI_prox_03_01_dT',
  },
  {
    label: 'THI[%]prox_06_01_dT',
    name: 'THI_prox_06_01_dT',
  },
  {
    label: 'THI[%]prox_09_01_dT',
    name: 'THI_prox_09_01_dT',
  },
  {
    label: 'THI[%]prox_12_01_dT',
    name: 'THI_prox_12_01_dT',
  },
  {
    label: 'THI[%]_prox_tot_01_dT',
    name: 'THI_prox_tot_01_dT',
  },
  {
    label: 'TWI[%]prox_03_01_dT',
    name: 'TWI_prox_03_01_dT',
  },
  {
    label: 'TWI[%]prox_06_01_dT',
    name: 'TWI_prox_06_01_dT',
  },
  {
    label: 'TWI[%]prox_09_01_dT',
    name: 'TWI_prox_09_01_dT',
  },
  {
    label: 'TWI[%]prox_12_01_dT',
    name: 'TWI_prox_12_01_dT',
  },
  {
    label: 'TWI[%]_prox_tot_01_dT',
    name: 'TWI_prox_tot_01_dT',
  },
]
