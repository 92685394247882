import { FormItemProps } from 'antd'
import { IRecord } from '../../../models/Records.model'

export const recordsDrawerFormFieldsDist: FormItemProps<IRecord>[] = [
  {
    label: 'StO2[%]dist_03_01',
    name: 'StO2_dist_03_01',
  },
  {
    label: 'StO2[%]dist_06_01',
    name: 'StO2_dist_06_01',
  },
  {
    label: 'StO2[%]dist_09_01',
    name: 'StO2_dist_09_01',
  },
  {
    label: 'StO2[%]dist_12_01',
    name: 'StO2_dist_12_01',
  },
  {
    label: 'StO2[%]dist_tot_01',
    name: 'StO2_dist_tot_01',
  },
  {
    label: 'NPI[%]dist_03_01',
    name: 'NPI_dist_03_01',
  },
  {
    label: 'NPI[%]dist_06_01',
    name: 'NPI_dist_06_01',
  },
  {
    label: 'NPI[%]dist_09_01',
    name: 'NPI_dist_09_01',
  },
  {
    label: 'NPI[%]dist_12_01',
    name: 'NPI_dist_12_01',
  },
  {
    label: 'NPI[%]dist_tot_01',
    name: 'NPI_dist_tot_01',
  },
  {
    label: 'THI[%]dist_03_01',
    name: 'THI_dist_03_01',
  },
  {
    label: 'THI[%]dist_06_01',
    name: 'THI_dist_06_01',
  },
  {
    label: 'THI[%]dist_09_01',
    name: 'THI_dist_09_01',
  },
  {
    label: 'THI[%]dist_12_01',
    name: 'THI_dist_12_01',
  },
  {
    label: 'THI[%]_dist_tot_01',
    name: 'THI_dist_tot_01',
  },
  {
    label: 'TWI[%]dist_03_01',
    name: 'TWI_dist_03_01',
  },
  {
    label: 'TWI[%]dist_06_01',
    name: 'TWI_dist_06_01',
  },
  {
    label: 'TWI[%]dist_09_01',
    name: 'TWI_dist_09_01',
  },
  {
    label: 'TWI[%]dist_12_01',
    name: 'TWI_dist_12_01',
  },
  {
    label: 'TWI[%]_dist_tot_01',
    name: 'TWI_dist_tot_01',
  },
]
