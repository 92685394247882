import React, { useEffect } from 'react'
import styles from './Dashboard.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { removeUser } from '../../store/slices/applicationSlice'
import { useHistory } from 'react-router-dom'
import Cookie from 'js-cookie'
import { Button, message, PageHeader, Typography } from 'antd'
import { RecordsTable } from './RecordsTable/RecordsTable'
import { RecordsDrawer } from './RecordsDrawer/RecordsDrawer'
import {
  fetchRecordsData,
  selectRecordsData,
  selectRecordsDataOutdatedStatus,
  selectRecordsFetchingStatus,
  setRecordsDataOutdatedStatus,
} from '../../store/slices/recordsSlice'
import {
  selectRecordsDrawerOpenState,
  setIsDrawerOpen,
  setFormSelectedRecord,
} from '../../store/slices/recordsDrawerSlice'

const { Title } = Typography

export const Dashboard = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const isRecordsDrawerOpen = useSelector(selectRecordsDrawerOpenState)

  const recordsData = useSelector(selectRecordsData)
  const recordsFetchingStatus = useSelector(selectRecordsFetchingStatus)
  const recordsDataOutdatedStatus = useSelector(selectRecordsDataOutdatedStatus)

  useEffect(() => {
    dispatch(setRecordsDataOutdatedStatus(true))
  }, [])

  useEffect(() => {
    if (recordsDataOutdatedStatus) {
      ;(async () => {
        const success = await dispatch(fetchRecordsData())
        if (!success) {
          message.warning('Messwerte konnten nicht geladen werden')
        }
      })()
    }
  }, [recordsDataOutdatedStatus])

  const handleSignOutUser = async () => {
    await dispatch(removeUser())
    Cookie.remove('jwt')
    history.push('/sign-in')
  }

  const openAddRecordDrawer = () => dispatch(setIsDrawerOpen(true))

  const handlerRecordsDrawerOnClose = () => {
    dispatch(setIsDrawerOpen(false))
    dispatch(setFormSelectedRecord(null))
  }

  return (
    <>
      <PageHeader
        className={styles.header}
        title="Dashboard"
        extra={[
          <Button key={'sign-out-button'} onClick={handleSignOutUser}>
            Abmelden
          </Button>,
        ]}
      />
      <div className={styles.container}>
        <div className={styles.head}>
          <Title level={3}>Messergebnisse</Title>
          <Button type={'primary'} onClick={openAddRecordDrawer}>
            Messergebnis hinzufügen
          </Button>
        </div>
        <RecordsTable loading={recordsFetchingStatus.isFetching} dataSource={recordsData} />
      </div>

      <RecordsDrawer
        visible={isRecordsDrawerOpen}
        onClose={handlerRecordsDrawerOnClose}
        handlerOnClose={handlerRecordsDrawerOnClose}
      />
    </>
  )
}
