import React from 'react'
import { ColumnsType } from 'antd/es/table'

import { recordsTableColumnsDist } from './recordsTableColumnsDist'
import { recordsTableColumnsDistDt } from './recordsTableColumnsDistDt'
import { recordsTableColumnsProx } from './recordsTableColumnsProx'
import { recordsTableColumnsProxDt } from './recordsTableColumnsProxDt'
import { Button, Dropdown, Menu, Tag } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'
import { ESex, ESkinType, IRecord } from '../../../models/Records.model'
import { useDispatch } from 'react-redux'
import { setFormSelectedRecord, setIsDrawerOpen } from '../../../store/slices/recordsDrawerSlice'

const ActionButton = (record: IRecord) => {
  const dispatch = useDispatch()
  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item key="0">
            <Button
              type={'link'}
              onClick={() => {
                dispatch(setFormSelectedRecord(record))
                dispatch(setIsDrawerOpen(true))
              }}
            >
              Bearbeiten
            </Button>
          </Menu.Item>
        </Menu>
      }
      trigger={['click']}
      placement={'bottomRight'}
    >
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <EllipsisOutlined />
      </a>
    </Dropdown>
  )
}

export const recordsTableColumns: ColumnsType<IRecord> = [
  {
    title: 'Probanden ID',
    dataIndex: 'subjectId',
    fixed: 'left',
    width: 500,
  },
  {
    title: 'Diagnosegruppe',
    dataIndex: 'diagnosisGroup',
    fixed: 'left',
    width: 145,
  },
  {
    title: 'Hauttyp',
    dataIndex: 'skinType',
    render: (sex: IRecord['skinType']) => (
      <Tag color={sex === ESkinType.LIGHT ? '#b3b3b3' : '#343434'}>{sex === ESkinType.LIGHT ? 'hell' : 'dunkel'}</Tag>
    ),
  },
  {
    title: 'Alter',
    dataIndex: 'age',
  },
  {
    title: 'Geschlecht',
    dataIndex: 'sex',
    render: (sex: IRecord['sex']) => (
      <Tag color={sex === ESex.MALE ? 'blue' : 'pink'}>{sex === ESex.MALE ? 'männlich' : 'weiblich'}</Tag>
    ),
  },
  {
    title: 'Körperhöhe [cm]',
    dataIndex: 'bodyHeight',
  },
  {
    title: 'Gewicht [kg]',
    dataIndex: 'weight',
  },
  {
    title: 'BMI [kg/m²]',
    dataIndex: 'bmi',
  },
  {
    title: 'Raucherstatus',
    dataIndex: 'smokerStatus',
    render: (isSmoker: boolean) => <Tag color={isSmoker ? 'red' : 'green'}>{isSmoker ? 'Ja' : 'Nein'}</Tag>,
  },
  {
    title: 'Pre proBNP [ng/ml]',
    dataIndex: 'preProBnp',
  },
  {
    title: 'Post proBNP [ng/ml]',
    dataIndex: 'postProBnp',
  },
  {
    title: 'T_derm [°C]',
    dataIndex: 'T_derm',
  },
  {
    title: 'T_ambient [°C]_02',
    dataIndex: 'T_ambient_02',
  },
  {
    title: 'tcpO2_dist[mmHg]_01',
    dataIndex: 'tcpO2_dist_01',
  },
  {
    title: 'tcpO2_prox[mmHg]_01',
    dataIndex: 'tcpO2_prox_01',
  },
  {
    title: 'tcpO2_dist[mmHg]_02',
    dataIndex: 'tcpO2_dist_02',
  },
  {
    title: 'tcpO2_prox[mmHg]_02',
    dataIndex: 'tcpO2_prox_02',
  },
  ...recordsTableColumnsDist,
  ...recordsTableColumnsProx,
  ...recordsTableColumnsDistDt,
  ...recordsTableColumnsProxDt,
  {
    title: 'Aktionen',
    key: 'operation',
    fixed: 'right',
    width: 150,
    render: ActionButton,
  },
]
