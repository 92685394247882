import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { IRecord } from '../../models/Records.model'

interface IInitialStateRecordsDrawerSlice {
  isDrawerOpen: boolean
  form: {
    selectedRecord: IRecord | null
  }
}

type SetRecordsDrawerOpenStatusPayloadAction = PayloadAction<boolean>
type SetRecordsDrawerFormSelectedRecordPayloadAction = PayloadAction<IRecord | null>

const initialState: IInitialStateRecordsDrawerSlice = {
  isDrawerOpen: false,
  form: {
    selectedRecord: null,
  },
}

const RECORDS_DRAWER_SLICE_NAME = 'recordsDrawer'

export const recordsDrawerSlice = createSlice({
  name: RECORDS_DRAWER_SLICE_NAME,
  initialState,
  reducers: {
    setIsDrawerOpen: (state, { payload }: SetRecordsDrawerOpenStatusPayloadAction) => {
      state.isDrawerOpen = payload
    },
    setFormSelectedRecord: (state, { payload }: SetRecordsDrawerFormSelectedRecordPayloadAction) => {
      state.form.selectedRecord = payload
    },
  },
})

export const { setIsDrawerOpen, setFormSelectedRecord } = recordsDrawerSlice.actions

export const selectRecordsDrawerOpenState = (state: RootState) => state[RECORDS_DRAWER_SLICE_NAME].isDrawerOpen
export const selectRecordsDrawerFormSelectedRecord = (state: RootState) =>
  state[RECORDS_DRAWER_SLICE_NAME].form.selectedRecord
