import { ColumnsType } from 'antd/es/table'
import { IRecord } from '../../../models/Records.model'

export const recordsTableColumnsProxDt: ColumnsType<IRecord> = [
  {
    title: 'StO2[%]prox_03_01_dT',
    dataIndex: 'StO2_prox_03_01_dT',
  },
  {
    title: 'StO2[%]prox_06_01_dT',
    dataIndex: 'StO2_prox_06_01_dT',
  },
  {
    title: 'StO2[%]prox_09_01_dT',
    dataIndex: 'StO2_prox_09_01_dT',
  },
  {
    title: 'StO2[%]prox_12_01_dT',
    dataIndex: 'StO2_prox_12_01_dT',
  },
  {
    title: 'StO2[%]prox_tot_01_dT',
    dataIndex: 'StO2_prox_tot_01_dT',
  },
  {
    title: 'NPI[%]prox_03_01_dT',
    dataIndex: 'NPI_prox_03_01_dT',
  },
  {
    title: 'NPI[%]prox_06_01_dT',
    dataIndex: 'NPI_prox_06_01_dT',
  },
  {
    title: 'NPI[%]prox_09_01_dT',
    dataIndex: 'NPI_prox_09_01_dT',
  },
  {
    title: 'NPI[%]prox_12_01_dT',
    dataIndex: 'NPI_prox_12_01_dT',
  },
  {
    title: 'NPI[%]prox_tot_01_dT',
    dataIndex: 'NPI_prox_tot_01_dT',
  },
  {
    title: 'THI[%]prox_03_01_dT',
    dataIndex: 'THI_prox_03_01_dT',
  },
  {
    title: 'THI[%]prox_06_01_dT',
    dataIndex: 'THI_prox_06_01_dT',
  },
  {
    title: 'THI[%]prox_09_01_dT',
    dataIndex: 'THI_prox_09_01_dT',
  },
  {
    title: 'THI[%]prox_12_01_dT',
    dataIndex: 'THI_prox_12_01_dT',
  },
  {
    title: 'THI[%]_prox_tot_01_dT',
    dataIndex: 'THI_prox_tot_01_dT',
  },
  {
    title: 'TWI[%]prox_03_01_dT',
    dataIndex: 'TWI_prox_03_01_dT',
  },
  {
    title: 'TWI[%]prox_06_01_dT',
    dataIndex: 'TWI_prox_06_01_dT',
  },
  {
    title: 'TWI[%]prox_09_01_dT',
    dataIndex: 'TWI_prox_09_01_dT',
  },
  {
    title: 'TWI[%]prox_12_01_dT',
    dataIndex: 'TWI_prox_12_01_dT',
  },
  {
    title: 'TWI[%]_prox_tot_01_dT',
    dataIndex: 'TWI_prox_tot_01_dT',
  },
]
