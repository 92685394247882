import { useEffect, useState } from 'react'
import Cookie from 'js-cookie'
import { config } from '../config'
import { batch, useDispatch, useSelector } from 'react-redux'
import { setUser, setAuthenticationStatus, selectCurrentUser } from '../store/slices/applicationSlice'

export const useAuth = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectCurrentUser)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<null | Error>(null)

  const jwtToken = Cookie.get('jwt')

  useEffect(() => {
    ;(async () => {
      if (jwtToken) {
        setIsLoading(true)
        dispatch(setAuthenticationStatus(false))
        try {
          const response = await fetch(`${config.backend.url}/auth/validate`, {
            method: 'post',
            credentials: 'include',
            mode: 'cors',
            redirect: 'follow',
            headers: {
              'Content-type': 'application/json',
              'Access-Control-Allow-Origin': 'http://localhost:8000',
            },
            body: JSON.stringify({
              jwt: jwtToken,
            }),
          })

          const data = await response.json()

          batch(() => {
            dispatch(setAuthenticationStatus(true))
            dispatch(setUser(data))
          })
        } catch (e) {
          setError(e)
          dispatch(setAuthenticationStatus(false))
        } finally {
          setIsLoading(false)
        }
      }
    })()
  }, [user.email])

  return {
    isLoading,
    error,
  }
}
