import React from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { SignIn } from './modules/SignIn/SignIn'
import { ProtectedRoute } from './utils/ProtectedRoute'
import { useAuth } from './utils/useAuth'
import { Link } from 'react-router-dom'
import { Dashboard } from './modules/Dashboard/Dashboard'
import { Loading } from './modules/core/Loading/Loading'

export const App = () => {
  const { isLoading } = useAuth()

  if (isLoading) {
    return <Loading />
  }

  return (
    <Router>
      <Switch>
        <Route path="/sign-in">
          <SignIn />
        </Route>
        <ProtectedRoute path={'/'} exact>
          <Redirect to={'/dashboard'} />
        </ProtectedRoute>
        <ProtectedRoute path="/dashboard" exact>
          <Dashboard />
        </ProtectedRoute>
        <ProtectedRoute path="/record" exact>
          <div>
            hello dashboard <Link to={'/dashboard'}>Record</Link>
          </div>
        </ProtectedRoute>
        <Route>
          <div>404</div>
        </Route>
      </Switch>
    </Router>
  )
}
