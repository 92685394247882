import { Action, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { IRecord } from '../../models/Records.model'
import axios from 'axios'
import { config } from '../../config'

enum EHttpMethods {
  'GET' = 'GET',
  'PUT' = 'PUT',
  'POST' = 'POST',
  'DELETE' = 'DELETE',
}

interface IFetchingStatus {
  isFetching: boolean
  httpMethod: null | EHttpMethods
}

interface IInitialStateRecordsSlice {
  data: IRecord[]
  fetchingStatus: IFetchingStatus
  isDataOutdated: boolean
}

type SetRecordsDataPayloadAction = PayloadAction<IRecord[]>
type SetRecordsFetchingStatusPayloadAction = PayloadAction<IFetchingStatus>
type SetRecordsDataOutdatedStatusPayloadAction = PayloadAction<boolean>

const initialState: IInitialStateRecordsSlice = {
  data: [],
  fetchingStatus: {
    isFetching: false,
    httpMethod: null,
  },
  isDataOutdated: false,
}

export const fetchRecordsData = (): ThunkAction<Promise<boolean>, RootState, void, Action> => async (dispatch) => {
  dispatch(
    setRecordsFetchingStatus({
      isFetching: true,
      httpMethod: EHttpMethods.GET,
    })
  )

  try {
    const response = await axios.get<IRecord[]>(`${config.backend.url}/records`, {
      withCredentials: true,
    })

    dispatch(setRecordsDataOutdatedStatus(false))
    dispatch(setRecordsData(response.data))
    return true
  } catch (e) {
    console.log(e)
    return false
  } finally {
    dispatch(
      setRecordsFetchingStatus({
        isFetching: false,
        httpMethod: null,
      })
    )
  }
}

export const addRecordsData = (data: IRecord[]): ThunkAction<Promise<boolean>, RootState, void, Action> => async (
  dispatch
) => {
  dispatch(
    setRecordsFetchingStatus({
      isFetching: true,
      httpMethod: EHttpMethods.PUT,
    })
  )

  try {
    await axios.put<IRecord[]>(
      `${config.backend.url}/records`,
      {
        records: data,
      },
      {
        withCredentials: true,
      }
    )

    dispatch(setRecordsDataOutdatedStatus(true))
    return true
  } catch (e) {
    console.log(e)
    return false
  } finally {
    dispatch(
      setRecordsFetchingStatus({
        isFetching: false,
        httpMethod: null,
      })
    )
  }
}

export const updateRecordsData = (
  recordId: IRecord['_id'],
  data: Partial<IRecord>
): ThunkAction<Promise<boolean>, RootState, void, Action> => async (dispatch) => {
  dispatch(
    setRecordsFetchingStatus({
      isFetching: true,
      httpMethod: EHttpMethods.POST,
    })
  )

  try {
    await axios.post<IRecord>(
      `${config.backend.url}/records/${recordId}`,
      {
        partialRecord: JSON.stringify(data),
      },
      {
        withCredentials: true,
      }
    )

    dispatch(setRecordsDataOutdatedStatus(true))
    return true
  } catch (e) {
    console.log(e)
    return false
  } finally {
    dispatch(
      setRecordsFetchingStatus({
        isFetching: false,
        httpMethod: null,
      })
    )
  }
}

const RECORDS_SLICE_NAME = 'records'

export const recordsSlice = createSlice({
  name: RECORDS_SLICE_NAME,
  initialState,
  reducers: {
    setRecordsData: (state, action: SetRecordsDataPayloadAction) => {
      state.data = action.payload
    },
    setRecordsFetchingStatus: (state, action: SetRecordsFetchingStatusPayloadAction) => {
      state.fetchingStatus = action.payload
    },
    setRecordsDataOutdatedStatus: (state, action: SetRecordsDataOutdatedStatusPayloadAction) => {
      state.isDataOutdated = action.payload
    },
  },
})

export const { setRecordsData, setRecordsFetchingStatus, setRecordsDataOutdatedStatus } = recordsSlice.actions

export const selectRecordsData = (state: RootState) => state[RECORDS_SLICE_NAME].data
export const selectRecordsFetchingStatus = (state: RootState) => state[RECORDS_SLICE_NAME].fetchingStatus
export const selectRecordsDataOutdatedStatus = (state: RootState) => state[RECORDS_SLICE_NAME].isDataOutdated
