import { configureStore } from '@reduxjs/toolkit'
import { applicationSlice } from './slices/applicationSlice'
import { recordsSlice } from './slices/recordsSlice'
import { recordsDrawerSlice } from './slices/recordsDrawerSlice'

export const store = configureStore({
  reducer: {
    application: applicationSlice.reducer,
    records: recordsSlice.reducer,
    recordsDrawer: recordsDrawerSlice.reducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
