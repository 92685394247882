import React, { useState } from 'react'
import styles from './SignIn.module.scss'
import { config } from '../../config'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { selectAuthenticationStatus, setUser } from '../../store/slices/applicationSlice'

import { Card, Input, Button, Form, message } from 'antd'
import axios from 'axios'

interface IFormFields {
  email: string
  password: string
}

export const SignIn: React.FC = () => {
  const isAuthenticated = useSelector(selectAuthenticationStatus)
  const [isSignInLoading, setIsSignInLoading] = useState(false)

  if (isAuthenticated) {
    return <Redirect to={'/dashboard'} />
  }

  const dispatch = useDispatch()
  const history = useHistory()

  const handleFormFinish = async (values: IFormFields) => {
    setIsSignInLoading(true)
    try {
      const response = await axios(`${config.backend.url}/auth/sign-in`, {
        method: 'post',
        data: values,
        withCredentials: true,
      })

      setIsSignInLoading(false)
      dispatch(setUser(response.data))
      history.push('/dashboard')
    } catch (error) {
      setIsSignInLoading(false)
      message.error('Anmeldung fehlgeschlagen')
      console.log('error: ', error)
    }
  }

  return (
    <div className={styles.container}>
      <Card className={styles.paper}>
        <Form layout={'vertical'} onFinish={handleFormFinish} suppressContentEditableWarning>
          <Form.Item
            label={'Email'}
            name={'email'}
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Bitte geben Sie eine Email an.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={'Passwort'}
            name={'password'}
            rules={[
              {
                required: true,
                message: 'Bitte geben Sie ein Passwort an.',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button type={'primary'} htmlType={'submit'} loading={isSignInLoading}>
              Anmelden
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}
