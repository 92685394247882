import React, { useState } from 'react'
import { Button, Drawer, DrawerProps, Popconfirm, Space } from 'antd'
import styles from './RecordsDrawer.module.scss'
import { RecordsDrawerContent } from './RecordsDrawerContent/RecordsDrawerContent'

export interface IRecordsDrawerProps extends DrawerProps {
  handlerOnClose?: () => void
}

export interface IRecordsDrawerFooterProps {
  handlerOnClose?: IRecordsDrawerProps['handlerOnClose']
  isSubmitting?: boolean
}

export const RecordsDrawerFooter: React.FC<IRecordsDrawerFooterProps> = ({ handlerOnClose, isSubmitting }) => {
  return (
    <Space className={styles.footer}>
      <Popconfirm
        placement={'topRight'}
        title={'Alle neu eingetragenen Werte werden verloren gehen!'}
        okText={'Verstanden'}
        cancelText={'Abbrechen'}
        onConfirm={handlerOnClose}
        okButtonProps={{ type: 'primary', danger: true }}
      >
        <Button danger>Abbrechen</Button>
      </Popconfirm>

      <Button type={'primary'} form={'records-form'} htmlType={'submit'} loading={isSubmitting}>
        Speichern
      </Button>
    </Space>
  )
}

export const RecordsDrawer: React.FC<IRecordsDrawerProps> = ({ handlerOnClose, ...restProps }) => {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)

  return (
    <Drawer
      width={450}
      title={'Messergebnis hinzufügen'}
      closable={false}
      maskClosable={false}
      footer={<RecordsDrawerFooter isSubmitting={isFormSubmitting} handlerOnClose={handlerOnClose} />}
      keyboard={false}
      destroyOnClose
      {...restProps}
    >
      <RecordsDrawerContent isFormSubmittingSetter={setIsFormSubmitting} handlerOnClose={handlerOnClose} />
    </Drawer>
  )
}
